<template>
  <b-modal
    ref="modal-create-task"
    header-class="p-0 border-0"
    content-class="p-4"
    body-class="p-0"
    hide-title
    ok-title="Zavrieť"
    size="lg"
    ok-only
    @ok="handleOk"
    @show="resetCourse()"
  >
    <template #modal-header="{ close }">
      <h5>{{ $t("task-assignment") }}</h5>
      <button class="bg-transparent border-0" type="button" aria-label="Close">
        <svg-cross-icon @click="close" />
      </button>
    </template>
    <hr />
    <b-form ref="task-form" @submit="handleSubmit">
      <div class="w-100">
        <label v-if="!this.course">{{ $t("select-course") }}</label>
        <div class="d-flex flex-row">
          <GroupCourseSearch
            v-if="!this.course"
            @selected="form.selectedCourse = $event"
            @inputValChange="inputVal = $event"
            style="z-index: 1"
          />
          <div v-else class="form-group class-select">
            <label>{{ $t("choose-class") }}</label>
            <b-form-select
              v-model="selectedGroup"
              :options="groupsOptions"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <div class="form-group mt-4">
        <label class="d-block">{{ $t("note-for-students") }}</label>
        <textarea
          class="note-text mt-3 p-2 w-100"
          v-model="form.note"
          rows="5"
          maxlength="1000"
          placeholder="Voľný text"
          autocomplete="off"
        ></textarea>
      </div>
      <GroupDeadlinePicker
        :preselectedCurrentDate="true"
        @date="form.dateFrom = $event"
        @time="form.timeFrom = $event"
        style="z-index: -1"
      >
        <template v-slot:calendar-title>
          <label>{{ $t("deadline-since-date") }}</label>
        </template>
        <template v-slot:clock-title>
          <label>{{ $t("deadline-since-time") }}</label>
        </template>
      </GroupDeadlinePicker>

      <GroupDeadlinePicker
        @date="form.dateTo = $event"
        @time="form.timeTo = $event"
        style="z-index: -1"
      >
        <template v-slot:calendar-title>
          <label>{{ $t("deadline-until-date") }}</label>
        </template>
        <template v-slot:clock-title>
          <label>{{ $t("deadline-until-time") }}</label>
        </template>
      </GroupDeadlinePicker>

      <a
        v-if="form.selectedCourse && quizPublicUrl"
        :href="quizPublicUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="quiz-link mt-4 d-flex align-items-center w-color-primary"
      >
        <svg-external-link-icon />
        <span class="font-weight-bold">{{ $t("quiz-preview") }}</span>
      </a>
    </b-form>
    <template #modal-footer="{ ok, cancel }">
      <b-button
        class="modal-btn"
        variant="primary"
        type="submit"
        @click="ok()"
        :disabled="!isValid || !datesValid"
        >{{ $t("assign-task") }}</b-button
      >
      <b-button class="modal-btn" variant="outline-danger" @click="cancel()">{{
        $t("cancel")
      }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import { get } from "lodash";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import axios from "/utils/axios";
import * as Sentry from "@sentry/vue";
import { getQuizFormUrls } from "/utils/helpers";

const api = {
  getGroups: (userId) => {
    return axios.getAuth("groups", {
      params: {
        sort: ["createdAt"],
        populate: ["members.user", "creator.user", "tasks.responses"],
        filters: {
          creator: {
            id: userId,
          },
          academicYear: {
            active: true,
          },
        },
      },
    });
  },
};

export default {
  components: {
    GroupDeadlinePicker: () =>
      import("/components/Group/GroupDeadlinePicker.vue"),
    "svg-cross-icon": () => import("/assets/icons/cross.svg?inline"),
    GroupCourseSearch: () => import("/components/Group/GroupCourseSearch"),
    "svg-external-link-icon": () =>
      import("/assets/icons/externalLink.svg?inline"),
  },

  props: {
    courses: {
      required: false,
      type: Array,
      default: () => [],
    },
    course: {
      required: false,
      type: Object,
    },
  },

  mounted() {
    if (this.course) {
      this._loadGroups();
      this.form.selectedCourse = this.course;
    }
  },

  data() {
    return {
      form: {
        selectedCourse: null,
        dateFrom: "",
        timeFrom: "",
        dateTo: "",
        timeTo: "",
        note: "",
      },
      inputVal: null,
      selectedGroup: null,
      groupsOptions: [
        { value: null, text: "Prosím vyberte si triedu", disabled: true },
      ],
    };
  },
  methods: {
    showModal() {
      this.$nextTick(() => {
        this.$refs["modal-create-task"].show();
      });
    },
    resetCourse() {
      this.form = {
        selectedCourse: null,
        dateTo: "",
        timeTo: "",
        dateFrom: "",
        timeFrom: "",
      };
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.isValid) return;

      const parseDate = (d, t) => {
        return moment(`${d} ${t}`, "YYYY-MM-DD HH:mm:ss");
      };

      const task = {
        courseId: this.course ? this.course.id : this.form.selectedCourse.id,
        startsAt: parseDate(this.form.dateFrom, this.form.timeFrom),
        deadline: parseDate(this.form.dateTo, this.form.timeTo),
        note: this.form.note,
      };

      if (this.selectedGroup) task.group_id = this.selectedGroup;

      this.$emit("createTask", task);
      this.$nextTick(() => {
        this.$refs["modal-create-task"].hide();
      });
    },
    async _loadGroups() {
      try {
        await this.$store.dispatch("auth/user");
        const groups = await api.getGroups(this.user.id);

        groups.data.forEach((group) => {
          this.groupsOptions.push({
            value: group.id,
            text: `${group.attributes.name} ${group.attributes.subject}`,
          });
        });
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    datesValid() {
      const diffDates = moment(this.form.dateTo, "YYYY-MM-DD").diff(
        moment(this.form.dateFrom, "YYYY-MM-DD"),
        "days"
      );
      const diffTimes = moment(this.form.timeTo, "hh:mm:ss").diff(
        moment(this.form.timeFrom, "hh:mm:ss"),
        "seconds"
      );
      if (diffDates === 0) {
        return diffTimes > 0;
      }
      return diffDates > 0;
    },
    isValid() {
      return this.selectedGroup != null || this.form.selectedCourse != null;
    },
    quizPublicUrl() {
      return getQuizFormUrls({
        course: get(this.form, "selectedCourse"),
        user: this.user,
      }).publicUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  label {
    padding: 0px;
    font-size: 16px;
  }
}
.class-select {
  border-bottom: 1px solid #cadeea;
  width: 100%;
}
svg {
  cursor: pointer;
}

hr {
  margin: 1.5rem 0;
  background: var(--a-color-blue-light);
}

h4 {
  font-family: Boing !important;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #2b2e4a;
}

.m-width-of-dd-item {
  max-width: 675px;
}

.calendar,
::v-deep .custom-select,
::v-deep .b-form-timepicker {
  border: none !important;
  background: none;
  &:active {
    border: none !important;
  }
  //border-bottom: 1px solid #CADEEA !important;
}

::v-deep .text-dark,
::v-deep .text-truncate,
::v-deep .text-center {
  color: #2b2e4a !important;
}

::v-deep .text-muted {
  color: rgba(156, 161, 174, 0.4) !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px !important;
  line-height: 27px !important;
}

::v-deep .modal-body {
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    margin-top: auto !important;
    margin-bottom: auto !important;
    align-items: center;

    color: #2b2e4a;
  }
  .modal-box {
    align-items: center;
    width: 569px;
    height: 94px;
    left: 617px;
    top: 847px;

    background: #fff5d1;
    border: 1px solid #e6d8a7;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .modal-box-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;

    letter-spacing: -0.05em;

    color: #cab979;
  }
}
input {
  border: none;
  width: 100%;
  color: #2b2e4a;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  &::placeholder {
    color: rgba(156, 161, 174, 0.4) !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
  }
  &:focus {
    outline: 0;
  }
  &:read-only {
    background-color: transparent;
  }
}

::v-deep .modal-content {
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-image: linear-gradient(90.01deg, #0064ff 60.75%, #fb2564 79.89%) 0 0
    100% 0/0 0 4.53px 0 stretch;
}

::v-deep .modal-footer {
  border-top: 0 none !important;
  align-items: left;
}

.modal-btn {
  width: 221px;
  height: 54px;
  left: 782px;
  top: 767px;
}

.box-content {
  margin: auto;
  padding: 20px;
  justify-content: center;
}

.box-bottom {
  width: 100%;
  background: #fff5d1;
  border: 1px solid #e6d8a7;
  box-sizing: border-box;
  align-items: center;
  border-radius: 4px;
}

.quiz-link {
  cursor: pointer;
}

.note-text {
  resize: none;
  border: 1px solid rgba(156, 161, 174, 0.4);

  &:focus {
    border: 1px solid rgba(156, 161, 174, 0.4);
  }
}
</style>
